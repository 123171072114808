

<script>
import { useRouter } from "vue-router";
import { ref, reactive, provide, computed } from "vue";
import { useStore } from "vuex";
import Property from "./Property.vue";
import vuexStore from "../../../store";

export default {
  beforeRouteEnter(to, from, next) {
    const state = vuexStore.state.cases;
    const { property } = state;
    if (!property) {
      next({ name: 1 });
    } else {
      next();
    }
  },
  components: {
    Property,
  },
  setup() {
    provide("location", "North Pole");
    const router = useRouter();
    const store = useStore();
    const searchInput = ref(null);

    const sell = JSON.parse(JSON.stringify(store.state.cases.sell_case));
    const purchase = JSON.parse(
      JSON.stringify(store.state.cases.purchase_case)
    );

    const sell_case = reactive(sell);

    const purchase_case = reactive(purchase);

    const property_type = computed(() => store.state.cases.property);

    provide("sale", sell_case);
    provide("purchase", purchase_case);

    const handleSubmit = () => {
      store.dispatch("cases/updateCase", {
        sell_case: { ...sell_case },
        purchase_case: { ...purchase_case },
      });
      router.push({ name: 3 });
    };
    
    return {
      handleSubmit,
      searchInput,
      sell_case,
      purchase_case,
      property_type,
    };
  },
};
</script>
<template>
  <div class="grid gap-y-4 max-w-3xl mx-auto pb-8">
    <div>
      <h2 class="text-3xl font-extrabold text-gray-900 text-center">
        Property information
      </h2>
    </div>
    <div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="handleSubmit" class="space-y-8">
          <Property v-if="property_type === 'sale'" property_type="sale" />
          <Property
            v-if="property_type === 'purchase'"
            property_type="purchase"
          />
          <template v-if="property_type === 'both'">
            <Property property_type="sale" />
            <Property property_type="purchase" />
          </template>
          <button
            type="submit"
            class="
              inline-flex
              float-right
              w-full
              max-w-xs
              justify-center
              items-center
              px-4
              py-2
              border border-transparent
              text-base
              font-medium
              rounded-md
              shadow-sm
              text-white
              bg-light-blue-700
              hover:bg-light-blue-800
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:light-blue-500
            "
          >
            Save
          </button>
        </form>
      </div>
    </div>
  </div>
</template>



