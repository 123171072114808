<template>
<div>
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div class="px-4 py-5 bg-white space-y-4 sm:p-6 max-w-lg">
      <div>
        <label
          :for="'amount' + property_type"
          class="block text-sm font-medium text-gray-700"
        >
          {{ property_type === "purchase" ? "Buying" : "Selling" }} Price
        </label>
        <div
          class="
            mt-1
            relative
            rounded-md
            shadow-sm
            flex
            lg:gap-0
            gap-2
            lg:flex-nowrap
            flex-wrap
          "
        >
          <div class="relative flex focus-within:z-10 w-full">
            <div
              class="
                absolute
                inset-y-0
                left-0
                pl-3
                flex
                items-center
                pointer-events-none
              "
            >
              <CurrencyPoundIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              required
              type="text"
              step="0.01"
              v-model="property.amount"
              name="amount"
              :id="'amount' + property_type"
              class="
                block
                rounded-none rounded-l-md
                pl-10
                sm:text-sm
                border-gray-300
                w-full
              "
              @input="handleOnChange"
              placeholder="Amount"
            />
          </div>
          <div class="flex items-center bg-gray-50 w-full relative">
            <label :for="'type' + property_type" class="sr-only"
              >Currency</label
            >
            <span
              class="
                text-gray-700
                absolute
                left-2
                select-none
                z-0
                font-medium
                sm:text-sm
              "
              v-if="!property.property_type"
              >Select property type</span
            >
            <select
              v-model="property.property_type"
              required
              :id="'type' + property_type"
              name="type"
              class="
                z-10
                focus:ring-blue-500 focus:border-blue-500
                h-10
                w-full
                py-0
                pl-2
                pr-7
                border-gray-300
                bg-transparent
                text-gray-700
                font-medium
                sm:text-sm
                rounded-none rounded-r-md
              "
            >
              <option
                :key="type.id"
                v-for="type in property_types"
                :value="type.id"
              >
                {{ type.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <hr class="bg-gray-200" />

      <span class="relative z-0 inline-flex shadow-sm rounded-md" style="display:none;">
        <span
          class="
            relative
            inline-flex
            items-center
            px-2
            py-2
            rounded-l-md
            border border-gray-300
            bg-white
          "
        >
          <input
            v-model="property.is_mortgage"
            :id="'mortgage' + property_type"
            type="checkbox"
            name="select-all"
            class="
              h-4
              w-4
              text-blue-600
              focus:ring-blue-500
              border-gray-300
              rounded
            "
          />
        </span>
        <label
          name="mortgage"
          class="
            -ml-px
            block
            w-full
            pl-3
            pr-9
            py-2
            rounded-l-none rounded-r-md
            border border-gray-300
            bg-white
            text-sm
            font-medium
            text-gray-700
            hover:bg-gray-50
            focus:z-10
            focus:outline-none
            focus:ring-1
            focus:ring-blue-500
            focus:border-blue-500
          "
          :for="'mortgage' + property_type"
          >I will be repaying a mortgage</label
        >
      </span>
    </div>
  </div>
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
      <div class="relative">
        <input
          type="text"
          placeholder="Postcode or address"
          class="
            shadow-sm
            focus:ring-blue-500 focus:border-blue-500
            block
            w-full-map
            sm:text-sm
            border-gray-300
            rounded-md
            top-3
            left-3
            py-3
            absolute
            z-10
          "
          onkeydown="return (event.keyCode!=13);"
          ref="searchInput"
        />
        <!-- <img src="@/assets/img/map-placeholder.svg" /> -->
        <div ref="mapDiv" style="width: 100%; height: 50vh" />
      </div>
      <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-6">
          <label
            :for="'street_address' + property_type"
            class="block text-sm font-medium text-gray-700"
          >
            Street address
          </label>
          <div class="mt-1">
            <input
              required
              v-model="property.address.street_address"
              type="text"
              name="street_address"
              :id="'street_address' + property_type"
              autocomplete="street-address"
              class="
                shadow-sm
                focus:ring-blue-500 focus:border-blue-500
                block
                w-full
                sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>

        <div class="sm:col-span-2">
          <label
            :for="'postcode' + property_type"
            class="block text-sm font-medium text-gray-700"
          >
            Postcode
          </label>
          <div class="mt-1">
            <input
              v-model="property.address.postcode"
              type="text"
              name="postcode"
              required
              :id="'postcode' + property_type"
              class="
                shadow-sm
                focus:ring-blue-500 focus:border-blue-500
                block
                w-full
                sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>

        <div class="sm:col-span-2">
          <label
            :for="'city' + property_type"
            class="block text-sm font-medium text-gray-700"
          >
            City
          </label>
          <div class="mt-1">
            <input
              v-model="property.address.city"
              type="text"
              name="city"
              :id="'city' + property_type"
              required
              class="
                shadow-sm
                focus:ring-blue-500 focus:border-blue-500
                block
                w-full
                sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>
        <div class="sm:col-span-2">
          <label
            :for="'country' + property_type"
            class="block text-sm font-medium text-gray-700"
          >
            Country
          </label>
          <div class="mt-1">
            <input
              required
              v-model="property.address.country"
              type="text"
              name="country"
              :id="'country' + property_type"
              class="
                shadow-sm
                focus:ring-blue-500 focus:border-blue-500
                block
                w-full
                sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { ref, onMounted, inject } from "vue";
import { CurrencyPoundIcon } from "@heroicons/vue/solid";
import { getPropertyTypes } from "../../../services/case";
import { Loader } from "@googlemaps/js-api-loader";
export default {
  components: {
    CurrencyPoundIcon
  },
  props: ["property_type"],
  setup(props) {
    const property = inject(props.property_type);
    const property_types = ref([]);
    const searchInput = ref(null);
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      libraries: ["places"]
    });
    const mapDiv = ref(null);
    let map = ref(null);
    let google = ref(null);

    onMounted(async () => {
      await loader.load();
      const { data } = await getPropertyTypes();
      property_types.value = data;
      map.value = new window.google.maps.Map(mapDiv.value, {
        center: JSON.parse(property.address.coords),
        streetViewControl: false,
        mapTypeControl: false,
        zoom: 15
      });

      const isSupported = "navigator" in window && "geolocation" in navigator;

      if (isSupported)
        navigator.geolocation.getCurrentPosition(position => {
          property.address.coords = JSON.stringify({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
          map.value.setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
          map.value.setZoom(15);
        });

      google.value = window.google;
      const searchBox = new google.value.maps.places.SearchBox(
        searchInput.value
      );
      let markers = [];
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();
        if (places.length == 0) {
          return;
        }
        // Clear out the old markers.
        markers.forEach(marker => {
          marker.setMap(null);
        });
        markers = [];
        // For each place, get the icon, name and location.
        const bounds = new google.value.maps.LatLngBounds();
        places.forEach(place => {
          if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
          }

          // Create a marker for each place.
          let marker = new google.value.maps.Marker({
            map: map.value,
            title: place.name,
            position: place.geometry.location,
            draggable: true
          });

          geocoderToAdress(marker.position);

          google.value.maps.event.addListener(marker, "dragend", function(
            marker
          ) {
            var latLng = marker.latLng;
            property.address.coords = JSON.stringify({
              lat: latLng.lat(),
              lng: latLng.lng()
            });
            geocoderToAdress(latLng);
          });

          markers.push(marker);

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });

        map.value.fitBounds(bounds);
        map.value.setCenter(markers[0].getPosition());
        map.value.setZoom(15);
      });
    });

    const geocoderToAdress = latLng => {
      var geocoder = new google.value.maps.Geocoder();
      geocoder.geocode(
        {
          latLng
        },
        function(results, status) {
          if (status == google.value.maps.GeocoderStatus.OK) {
            const fullAdress = results.find(i =>
              i.types.includes("street_address")
            );
            if (fullAdress?.formatted_address) {
              property.address.street_address = fullAdress.formatted_address;
              for (let adress of fullAdress.address_components) {
                if (adress.types.includes("postal_code")) {
                  property.address.postcode = adress.long_name;
                }
                if (adress.types.includes("country")) {
                  property.address.country = adress.long_name;
                }
                if (adress.types.includes(`administrative_area_level_2`)) {
                  property.address.city = adress.long_name;
                } else if (
                  adress.types.includes(`administrative_area_level_1`)
                ) {
                  property.address.city = adress.long_name;
                }
              }
            }
          }
        }
      );
    };
    const handleOnChange = e => {
      let value = e.target.value.replaceAll(",", "");
      property.amount = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    return {
      handleOnChange,
      property,
      property_types,
      searchInput,
      mapDiv
    };
  }
};
</script>

<style scoped>
.w-full-map {
  width: calc(100% - 100px);
}
:deep().gm-fullscreen-control {
  margin-top: 14px !important;
}
</style>
